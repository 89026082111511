import React from "react";
import PropTypes from "prop-types";

export default function BellIcon({ unrededCount = 0 }) {
  return (
    <div className="rounded-[8px] py-[7px] px-[9px] mx-[5px]  bg-[#F5F7FA] relative ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
      >
        <path
          d="M1 13.8851H0.25H1ZM3.73291 17.0834L3.83592 16.3405H3.83592L3.73291 17.0834ZM13.9193 17.0834L13.8163 16.3405H13.8162L13.9193 17.0834ZM3.48914 7.08696C3.48914 4.13944 5.87857 1.75 8.82609 1.75V0.25C5.05015 0.25 1.98914 3.31101 1.98914 7.08696H3.48914ZM3.48914 9.17949V7.08696H1.98914V9.17949H3.48914ZM1.75 13.8851C1.75 13.4037 1.88171 12.9552 2.11062 12.5714L0.822315 11.8031C0.458701 12.4128 0.25 13.1257 0.25 13.8851H1.75ZM3.83592 16.3405C2.63629 16.1742 1.75 15.1313 1.75 13.8851H0.25C0.25 15.8448 1.65411 17.5524 3.6299 17.8263L3.83592 16.3405ZM8.82609 16.772C7.33411 16.772 5.40652 16.5583 3.83592 16.3405L3.6299 17.8263C5.21268 18.0458 7.22537 18.272 8.82609 18.272V16.772ZM13.8162 16.3405C12.2457 16.5583 10.3181 16.772 8.82609 16.772V18.272C10.4268 18.272 12.4395 18.0458 14.0223 17.8263L13.8162 16.3405ZM15.9022 13.8851C15.9022 15.1313 15.0159 16.1742 13.8163 16.3405L14.0223 17.8263C15.9981 17.5524 17.4022 15.8448 17.4022 13.8851H15.9022ZM15.5416 12.5714C15.7705 12.9552 15.9022 13.4037 15.9022 13.8851H17.4022C17.4022 13.1257 17.1935 12.4128 16.8299 11.8031L15.5416 12.5714ZM14.1631 7.08696V9.17951H15.6631V7.08696H14.1631ZM8.82609 1.75C11.7736 1.75 14.1631 4.13944 14.1631 7.08696H15.6631C15.6631 3.31101 12.602 0.25 8.82609 0.25V1.75ZM16.8299 11.8031C16.521 11.2851 16.2404 10.8701 16.0091 10.4066C15.7876 9.96289 15.6631 9.567 15.6631 9.17951H14.1631C14.1631 9.89235 14.3931 10.5278 14.667 11.0766C14.9311 11.6056 15.2869 12.1443 15.5416 12.5714L16.8299 11.8031ZM1.98914 9.17949C1.98914 9.56698 1.86462 9.96287 1.64311 10.4066C1.41176 10.8701 1.13121 11.2851 0.822315 11.8031L2.11062 12.5714C2.36531 12.1443 2.7211 11.6056 2.98519 11.0766C3.25912 10.5278 3.48914 9.89234 3.48914 9.17949H1.98914Z"
          fill="#1D68DF"
        />
        <path
          d="M11 19.834C10.5326 20.5369 9.73347 21.0002 8.82609 21.0002C7.91871 21.0002 7.11954 20.5369 6.65218 19.834"
          stroke="#1D68DF"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      {unrededCount != 0 && (
        <h4 className="bg-red-500 text-white rounded-[50%] min-w-[16px] text-center min-h-[16px] pt-[3px] absolute top-[-2px] right-[-2px] text-[8px] ">
          {unrededCount}
        </h4>
      )}
    </div>
  );
}
BellIcon.propTypes = {
  unrededCount: PropTypes.any,
};
