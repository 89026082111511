import React, { useState } from "react";
import PropTypes from "prop-types";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import SucsessMessage from "../reuseables/SucsessMessage";
import CustomSubmitButton from "../reuseables/CustomSubmitButton";
import DeleteIcon from "../icons/DeleteIcon";
export default function DeleteConfirmMessage({
  close,
  updateData,
  APIurl,
  APIMethod,
  message,
  APIbody
}) {
  const [loader, setloader] = useState({ status: "" });
  const [submitErr, setsubmitErr] = useState();
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleDelete = (e) => {
    e.preventDefault();
    setloader({ status: "loading" });
    myFeach(`${APIurl}`, APIMethod,APIbody?APIbody: {}, "token")
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: `تم ${message}`,
        });
        setloader({ status: "done" });
        setTimeout(() => {
          close();
        }, 1000);
        updateData();
      })
      .catch((err) => {
        setloader({ status: "failed" });
        let errtext = parceErr(err)?.error ? parceErr(err)?.error : `فشل ${message}`;
        setopenFeedBackMessage({
          status: true,
          message: errtext,
        });
        setsubmitErr(errtext);
      });
  };
  return (
    <>
      {openFeedBackMessage.status ? (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      ) : (
        <div>
          <button onClick={close} className="modal_bg"></button>
          <div className="modal_content p-10 flex flex-col items-center">
            <div className="flex items-start p-2 mb-8">
              <div className="me-[8px] bg-red-500 w-[30px] h-[30px] rounded-[50%] flex  justify-center items-center">
                <DeleteIcon stroke="white" />
              </div>
              <h3 className="text-center" >هل أنت متأكد من {message}</h3>
            </div>

            <form
              className="border_top_greay pt-6 pb-4 w-full flex justify-center "
              onSubmit={handleDelete}
            >
              <CustomSubmitButton
                loader={loader}
                err={submitErr}
                width="112px"
                height="32px"
                title="تأكيد"
              />
              <button
                type="button"
                className="mx-2 w-28 h-8 gradient_border"
                onClick={close}
              >
                إلغاء
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
DeleteConfirmMessage.propTypes = {
  close: PropTypes.func,
  updateData: PropTypes.func,
  id: PropTypes.string,
  studentName: PropTypes.string,
  APIMethod: PropTypes.string,
  APIurl: PropTypes.string,
  message: PropTypes.string,
  APIbody: PropTypes.object,
};
