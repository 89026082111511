import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import EscIcon from "../../icons/EscIcon";
import CustomInput from "../../reuseables/CustomInput";
import {
  checkRequired,
  removeEmptyValues,
} from "../../../globalFunctions/validation";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import { useParams } from "react-router-dom";

export default function AddContentToStudent({ close, updateContent, student }) {
  const prams = useParams();
  const [courses, setcourses] = useState({
    loader: { status: "loading", text: "" },
    list: [],
  });
  const [sections, setsections] = useState({
    loader: { status: "loading", text: "" },
  });
  const [formData, setformData] = useState({
    data: {},
    submitLoader: { status: "" },
    errors: {},
    typeOfContent: "",
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSelectSection = (id) => {
    if (formData.data?.sections?.includes(id)) {
      let selected = [...formData.data.sections];

      setformData({
        ...formData,
        data: {
          ...formData.data,
          sections: [...selected.filter((elem) => elem != id)],
        },
      });
    } else {
      let selected = formData.data?.sections ? [...formData.data.sections] : [];
      setformData({
        ...formData,
        data: {
          ...formData.data,
          sections: [...selected, id],
        },
      });
    }
  };
  const handleGetCoursesList = () => {
    myFeach(`courses?availableToAdd=true`, "GET", {}, "token")
      .then(({ data }) => {
        console.log(data.courses.length);
        setcourses({ loader: { status: "done" }, list: data.courses });
      })
      .catch((err) => {
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل  الكورسات";
        setcourses({ loader: { status: "failed", text: errText } });
      });
  };
  const handleGetSectionsList = () => {
    setsections({ ...sections, loader: { status: "loading" } });
    myFeach(
      `students/${prams.id}/not-enrolled-sections/${formData.data.course}`,
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        if (data.sections.length == 0) {
          setsections({
            loader: { status: "failed", text: "لا يوجد أقسام لعرضها" },
          });
        } else {
          setsections({ loader: { status: "done" }, list: data.sections });
        }
      })
      .catch((err) => {
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل  الكورسات";
        setsections({ loader: { status: "failed", text: errText } });
      });
  };
  const handleAddSection = () => {
    myFeach(
      `students/${prams.id}/add-content`,
      "POST",
      { ...removeEmptyValues(formData.data) },
      "token"
    )
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم إضافة المحتوى بنجاح",
        });
        setformData({
          data: { name: "", price: "", discountedPrice: "", description: "" },
          submitLoader: { status: "done" },
          error: "",
          errors: {},
        });
        updateContent();
        setTimeout(() => {
          close();
        }, 1000);
      })
      .catch((err) => {
        setformData({
          ...formData,
          errors: parceErr(err).errors,
          error: parceErr(err).error
            ? parceErr(err).error
            : "فشل إضافة المحتوى",
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let arrayOfReq =
      formData.typeOfContent == "sections"
        ? ["course", "sections"]
        : ["course"];
    let requiredErrs = checkRequired(
      formData.data,
      [...arrayOfReq],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        errors: {},
        error: "",
      });
      handleAddSection();
    }
  };

  useEffect(() => {
    handleGetCoursesList();
  }, []);
  useEffect(() => {
    if (formData.data.course && formData.typeOfContent == "sections") {
      handleGetSectionsList();
    }
  }, [formData.data.course, formData.typeOfContent]);
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div>
        <button onClick={close} className="modal_bg"></button>
        <form
          className="modal_content  w-11/12 md:w-5/12 "
          onSubmit={handleSubmit}
        >
          <div className="relative  bg_primary  modal_title">
            <h3 className="w-fit  py-3 text_white mr-[10px]">
              إضافة محتوى للطالب
              {student?.name}
            </h3>
            <button
              className="absolute left-4 top-4 "
              type="button"
              onClick={close}
            >
              <EscIcon type="rounded" />
            </button>
          </div>
          <div className="p-8 mt-4">
            <CustomInput
              width="100%"
              lable={"اسم الكورس"}
              err={false}
              required={true}
              type="select"
              name="course"
              loader={courses.loader}
              options={[{ id: "", name: "" }, ...courses.list]}
              setformData={setformData}
              formData={formData}
            />
            <h6 className="text_secondary my-[8px] ">
              قم بتحديد المحتوى الذي ترغب بإضافته للطالب
            </h6>
            <div className="my-[8px]">
              <div className="flex items-center">
                <input
                  onChange={() => {
                    setformData({
                      ...formData,
                      typeOfContent: "all",
                    });
                  }}
                  type="radio"
                  disabled={formData.data.course ? false : true}
                  className="ml-[5px] w-[20px] mb-[5px]"
                  name="type"
                  required
                />
                <label className="font-bold">الكورس بالكامل</label>
              </div>
              <div className="flex items-center">
                <input
                  onChange={() => {
                    setformData({
                      ...formData,
                      typeOfContent: "sections",
                    });
                  }}
                  disabled={formData.data.course ? false : true}
                  type="radio"
                  className="ml-[5px] w-[20px] mb-[5px]"
                  name="type"
                  required
                />
                <label className="font-bold">
                  اختيار الأقسام{" "}
                  <span className="font-normal">(قسم واحد على الأقل)</span>
                </label>
              </div>
            </div>
            <div className="min-h-[150px]">
              {formData.typeOfContent == "sections" && (
                <div className="flex flex-wrap">
                  {sections.loader.status == "loading" ? (
                    <div className="flex items-center">
                      <div className="loader ml-[10px] mr-[0px] "></div>
                      <h4 className="mr-[10px]">
                        يتم تحميل الأقسام الغير مشترك فيها بالفعل
                      </h4>
                    </div>
                  ) : sections.loader.status == "failed" ? (
                    <h5 className="text-red-400">{sections.loader.text}</h5>
                  ) : (
                    <>
                      {sections.list.map((elem, index) => (
                        <div key={index} className="flex items-start ml-[20px]">
                          <input
                            type="checkbox"
                            className="h-[25px] ml-[5px] w-[15px] block"
                            onChange={() => handleSelectSection(elem.id)}
                          />
                          <label>{elem.name}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
              <h6 className="text-red-400">{formData.errors?.sections}</h6>
            </div>
            <CustomSubmitButton
              loader={formData.submitLoader}
              err={formData?.error}
              width="100%"
              height="47px"
              title="إضافة المحتوى"
            />
          </div>
        </form>
      </div>
    </>
  );
}
AddContentToStudent.propTypes = {
  close: PropTypes.func,
  updateContent: PropTypes.func,
  sectionData: PropTypes.object,
  student: PropTypes.object,
};
