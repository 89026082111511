import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import CustomInput from "../../reuseables/CustomInput";
import TwitterIcon from "../../icons/TwitterIcon";
import FaceBookIcon from "../../icons/FaceBookIcon";
import InstgramIcon from "../../icons/InstgramIcon";
import PhoneInput from "../../reuseables/PhoneInput";
import SelectFromCheckBox from "../../reuseables/SelectFromCheckBox";
import CustomUploadeInput from "../../reuseables/CustomUploadeInput";
import {
  checkRequired,
  compareObjects,
} from "../../../globalFunctions/validation";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import SucsessMessage from "../../reuseables/SucsessMessage";
import ResultMessage from "../../reuseables/ResultMessage";

export default function PersonalDataForm({ regetTopBarData }) {
  const [oldData, setoldData] = useState({
    loader: { status: "loading" },
    data: {},
  });
  const [personalFormData, setpersonalFormData] = useState({
    data: { title: "", categories: [] },
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const [titles, settitles] = useState({
    loader: { status: "" },
    data: [],
  });
  const getPersonalOldData = () => {
    myFeach("teachers/personal-data", "GET", {}, "token")
      .then(({ data }) => {
        setoldData({
          loader: { status: "done" },
          data: { ...handleFormatOldData(data) },
        });
        setpersonalFormData({
          ...personalFormData,
          data: { ...handleFormatOldData(data) },
        });
      })
      .catch((err) => {
        setoldData({
          loader: {
            status: "failed",
            text: parceErr(err).error
              ? parceErr(err).error
              : "فشل تحميل بياناتك الشخصية الحالية",
          },
        });
      });
  };
  const getTitles = () => {
    settitles({ loader: { status: "loading" } });
    if (titles.data.length) {
      setpersonalFormData({
        ...personalFormData,
        data: { ...personalFormData.data, title: "" },
      });
    }
    myFeach(`titles?gender=${personalFormData.data.gender}`, "GET", {}, "token")
      .then(({ data }) => {
        settitles({ ...titles, loader: { status: "done" }, data: data.titles });
      })
      .catch((err) => {
        console.log(err);
        settitles({
          ...titles,
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل الألقاب",
          },
        });
      });
  };
  const handleSupmitPersonalData = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      personalFormData.data,
      ["name", "gender", "whatsapp", "email", "whatsapp"],
      personalFormData,
      setpersonalFormData
    );
    if (requiredErrs === "sucsess") {
      setpersonalFormData({
        ...personalFormData,
        submitLoader: { status: "loading" },
        error: "",
        errors: {},
      });
      let comparedObject = compareObjects(personalFormData.data, oldData.data);
      console.log("compared:", comparedObject);
      myFeach("teachers/personal-data", "PUT", comparedObject, "token")
        .then(() => {
          setpersonalFormData({
            ...personalFormData,
            submitLoader: { status: "done" },
            error: "",
            errors: {},
          });
          setoldData({ ...oldData, data: { ...personalFormData.data } });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم تعديل بيانتك بنجاح",
          });
          regetTopBarData();
        })
        .catch((err) => {
          setpersonalFormData({
            ...personalFormData,
            submitLoader: { status: "failed" },
            error: parceErr(err)?.error ? parceErr(err)?.error : "",
            errors: parceErr(err)?.errors ? parceErr(err)?.errors : {},
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل تعديل البيانات حاول مرة أخرى",
          });
        });
    }
  };
  useEffect(() => {
    personalFormData.data.gender && getTitles();
  }, [personalFormData.data.gender]);
  useEffect(() => {
    getPersonalOldData();
  }, []);
  return (
    <div>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <h3 className="font-bold">البيانات الخاصة بالمدرس</h3>
      {oldData.loader.status == "failed" ? (
        <ResultMessage message={oldData.loader.text} />
      ) : (
        <form className="mt-10" onSubmit={handleSupmitPersonalData}>
          <CustomInput
            width="100%"
            lable={"الاسم"}
            comment={""}
            placeHolder={""}
            required={true}
            name="name"
            setformData={setpersonalFormData}
            formData={personalFormData}
            getOldDataLoader={oldData.loader.status}
          />
          <div className="flex  justify-between mt-8 w-full flex-wrap">
            <CustomInput
              width="100%"
              lable={"الجنس"}
              err={false}
              options={[
                { name: "", value: "" },
                { name: "ذكر", value: "ذكر" },
                { name: "أنثى", value: "أنثى" },
              ]}
              required={true}
              type="select"
              name="gender"
              setformData={setpersonalFormData}
              formData={personalFormData}
              getOldDataLoader={oldData.loader.status}
              containerClass="md:w-6/12 w-full md:pl-2 pl-0"
            />
            <CustomInput
              width="100%"
              lable={"اللقب"}
              err={titles?.err}
              type="select"
              name="title"
              disabled={personalFormData.data?.gender ? false : true}
              options={
                titles.data ? [{ name: "", id: "" }, ...titles.data] : ""
              }
              comment="يجب اختيار الجنس أولاً"
              loader={titles?.loader}
              setformData={setpersonalFormData}
              formData={personalFormData}
              getOldDataLoader={oldData.loader.status}
              containerClass="md:w-6/12 w-full md:pr-2 pl-0 "
            />
          </div>
          <div className="flex  justify-between mt-8 w-full flex-wrap items-center ">
            <PhoneInput
              lable={"رقم الهاتف"}
              disabled={true}
              err={false}
              required={true}
              width="100%"
              name="phone"
              setformData={setpersonalFormData}
              getOldDataLoader={oldData.loader.status}
              formData={personalFormData}
              comment="لا يمكن تعديل رقم الهاتف"
              containerClass="md:w-6/12 w-full md:pl-2 pl-0 mt-8"
            />
            <CustomInput
              width="100%"
              lable={"البريد الإلكتروني"}
              err={false}
              lang="en"
              required={true}
              // type="email"
              name="email"
              setformData={setpersonalFormData}
              getOldDataLoader={oldData.loader.status}
              formData={personalFormData}
              containerClass="md:w-6/12 w-full md:pr-2 pl-0 "
            />
          </div>
          <PhoneInput
            lable={"رقم ال whatsapp"}
            disabled={
              personalFormData.submitLoader.status === "loading" ? true : false
            }
            err={false}
            required={true}
            width="100%"
            name="whatsapp"
            setformData={setpersonalFormData}
            getOldDataLoader={oldData.loader.status}
            formData={personalFormData}
            comment="تأكد من أن الرقم له واتساب (قم باختيار الدولة أولا)"
            containerClass="md:w-6/12 w-full md:pl-2 pl-0 mt-8"
          />
          <CustomUploadeInput
            name="image"
            apiUrl="profileImage"
            formData={personalFormData}
            setformData={setpersonalFormData}
            disabled={
              personalFormData.submitLoader.status === "loading" ? true : false
            }
            lable="الصورة الشخصية"
            getOldDataLoader={oldData.loader.status}
            required={true}
          />
          {/*  */}
          <SelectFromCheckBox
            lable="التخصصات التي تقوم بتدريسها"
            required={true}
            disabled={
              personalFormData.submitLoader.status === "loading" ? true : false
            }
            formData={personalFormData}
            setformData={setpersonalFormData}
            objectKey={"categories"}
            getOldDataLoader={oldData.loader.status}
            apiUrl="categories"
          />
          <CustomInput
            disabled={
              personalFormData.submitLoader.status === "loading" ? true : false
            }
            width="100%"
            height="100px"
            lable={"نبذة عن المدرس"}
            required={true}
            type="textArea"
            name="brief"
            setformData={setpersonalFormData}
            getOldDataLoader={oldData.loader.status}
            formData={personalFormData}
          />
          {oldData.loader.status == "done" && (
            <div className="mt-10 mb-8  md:w-1/3 w-full">
              <h5>مواقع التواصل الاجتماعي</h5>
              <div className="flex items-center w-full justify-between mb-5 ">
                <div className="mt-2">
                  <InstgramIcon />
                </div>
                <CustomInput
                  disabled={
                    personalFormData.submitLoader.status === "loading"
                      ? true
                      : false
                  }
                  width="90%"
                  placeHolder="Instgram URL"
                  type=""
                  name="instagram"
                  setformData={setpersonalFormData}
                  formData={personalFormData}
                  getOldDataLoader={oldData.loader.status}
                  lang="en"
                  containerClass="w-full mr-4"
                />
              </div>
              <div className="flex items-center w-full justify-between mb-5 ">
                <div className="mt-2">
                  <FaceBookIcon />
                </div>
                <CustomInput
                  disabled={
                    personalFormData.submitLoader.status === "loading"
                      ? true
                      : false
                  }
                  width="90%"
                  err={false}
                  placeHolder="Facebook URL"
                  type=""
                  name="facebook"
                  setformData={setpersonalFormData}
                  getOldDataLoader={oldData.loader.status}
                  formData={personalFormData}
                  lang="en"
                  containerClass="w-full mr-4"
                />
              </div>
              <div className="flex items-center w-full justify-between mb-5 ">
                <div className="mt-2">
                  <TwitterIcon />
                </div>
                <CustomInput
                  disabled={
                    personalFormData.submitLoader.status === "loading"
                      ? true
                      : false
                  }
                  width="90%"
                  err={false}
                  placeHolder="Twitter URL"
                  name="X"
                  setformData={setpersonalFormData}
                  formData={personalFormData}
                  getOldDataLoader={oldData.loader.status}
                  lang="en"
                  containerClass="w-full mr-4"
                />
              </div>
            </div>
          )}
          <CustomSubmitButton
            loader={personalFormData.submitLoader}
            err={personalFormData?.error}
            disabled={
              personalFormData.submitLoader.status == "loading" ||
              oldData.loader.status != "done"
                ? true
                : false
            }
            width="100%"
            height="47px"
            title="حفظ"
          />
        </form>
      )}
    </div>
  );
}
PersonalDataForm.propTypes = {
  regetTopBarData: PropTypes.func,
};
let handleFormatOldData = (data) => {
  let formatedData = {
    ...data.personalData,
  };
  if (formatedData?.socialMediaContacts) {
    formatedData = {
      ...data.personalData,
      ...data.personalData.socialMediaContacts,
    };
    delete formatedData.socialMediaContacts;
  }
  return formatedData;
};
