import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import EscIcon from "../../icons/EscIcon";
import CustomInput from "../../reuseables/CustomInput";
import {
  checkRequired,
  compareObjects,
  removeEmptyValues,
} from "../../../globalFunctions/validation";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import { useParams } from "react-router-dom";

export default function AddSectionModal({
  close,
  regetCourseDetailes,
  sectionData,
}) {
  const prams = useParams();
  const [formData, setformData] = useState({
    data: { name: "" },
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleAddSection = () => {
    myFeach(
      "sections",
      "POST",
      { ...removeEmptyValues(formData.data), course: prams.id },
      "token"
    )
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم إضافة القسم بنجاح",
        });
        setformData({
          data: { name: "", price: "", discountedPrice: "", description: "" },
          submitLoader: { status: "done" },
          error: "",
          errors: {},
        });
        regetCourseDetailes();
      })
      .catch((err) => {
        console.log(err);
        setformData({
          ...formData,
          errors: parceErr(err).errors,
          error: parceErr(err).error ? parceErr(err).error : "فشل إضافة القسم",
        });
      });
  };
  const handleEditSection = () => {
    let changedFields = compareObjects(formData.data, sectionData);
    myFeach(`sections/${sectionData.id}`, "PUT", changedFields, "token")
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم تعديل القسم بنجاح",
        });
        setformData({
          data: { ...formData.data },
          errors: {},
          error: "",
          submitLoader: { status: "done" },
        });
        setTimeout(() => {
          close();
          regetCourseDetailes();
        }, 1500);
      })
      .catch((err) => {
        setopenFeedBackMessage({
          status: true,
          message: parceErr(err)?.error
            ? parceErr(err).error
            : "فشل تعديل القسم",
        });
        setformData({
          data: {
            ...formData.data,
          },
          errors: parceErr(err).errors,
          error: parceErr(err)?.error ? parceErr(err).err : "فشل تعديل القسم",
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["name", "price"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        errors: {},
        error: "",
      });
      if (sectionData) {
        handleEditSection();
      } else {
        handleAddSection();
      }
    }
  };
  useEffect(() => {
    setformData({ ...formData, data: sectionData });
  }, []);
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div>
        <button onClick={close} className="modal_bg"></button>
        <form
          className="modal_content  w-11/12 md:w-5/12 "
          onSubmit={handleSubmit}
        >
          <div className="relative  bg_primary  modal_title">
            <h3 className="w-fit m-auto py-3 text_white">
              {sectionData ? "تعديل القسم" : "إضافة قسم"}
            </h3>
            <button
              className="absolute left-4 top-4 "
              type="button"
              onClick={close}
            >
              <EscIcon type="rounded" />
            </button>
          </div>
          <div className="p-8 mt-4">
            <CustomInput
              width="100%"
              lable={"اسم القسم"}
              err={false}
              required={true}
              type=""
              name="name"
              setformData={setformData}
              formData={formData}
            />
            {!sectionData && (
              <CustomInput
                width="100%"
                checkBoxLabel="إضافة هذا القسم للطلبة المشتركين في الكورس من قبل"
                err={false}
                required={true}
                type="checkBox"
                name="addSectionToSubscribedStudents"
                containerClass="flex-row-reverse"
                setformData={setformData}
                formData={formData}
              />
            )}
            <div className="flex flex-col md:flex-row justify-between my-4  ">
              <CustomInput
                width="100%"
                lable={"السعر"}
                err={false}
                required={true}
                type="number"
                name="price"
                placeHolder="السعر بالريال"
                setformData={setformData}
                formData={formData}
                lang="en"
                containerClass="md:w-6/12 md:pl-2 w-full"
              />
              <CustomInput
                width="100%"
                lable={"السعر بعد الخصم"}
                err={false}
                type="number"
                placeHolder="السعر بالريال"
                name="discountedPrice"
                setformData={setformData}
                formData={formData}
                lang="en"
                containerClass="md:w-6/12 md:pl-2 w-full"
              />
            </div>
            <CustomInput
              width="100%"
              height="100px"
              lable={"وصف القسم"}
              err={false}
              type="textArea"
              name="description"
              setformData={setformData}
              formData={formData}
              containerClass="mb-4"
            />
            <CustomSubmitButton
              loader={formData.submitLoader}
              err={formData?.error}
              width="100%"
              height="47px"
              title={sectionData ? "تعديل" : "إضافة"}
            />
          </div>
        </form>
      </div>
    </>
  );
}
AddSectionModal.propTypes = {
  close: PropTypes.func,
  regetCourseDetailes: PropTypes.func,
  sectionData: PropTypes.object,
};
