import React, { useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "../../icons/DeleteIcon";
import { convertToDate } from "../../../globalFunctions/TextModify";
import ButtonArrow from "../../icons/ButtonArrow";
import TopArrow from "../../icons/TopArrow";
import DeleteConfirmMessage from "../../reuseables/DeleteConfirmMessage";
import { useParams } from "react-router-dom";

export default function StudentCourseCard({ courses, index, updateCourses }) {
  const prames = useParams();
  const [openConfirmDeleteModal, setopenConfirmDeleteModal] = useState({
    status: false,
    APIurl: "",
    message: "",
  });
  const [openSetionsList, setopenSetionsList] = useState(
    index == 0 ? true : false
  );
  return (
    <div className="w-full my_rounded bg-white p-[10px] mb-6">
      {openConfirmDeleteModal.status && (
        <DeleteConfirmMessage
          updateData={updateCourses}
          APIurl={openConfirmDeleteModal.APIurl}
          APIMethod="POST"
          message={openConfirmDeleteModal.message}
          APIbody={{ content: openConfirmDeleteModal.id }}
          close={() => setopenConfirmDeleteModal({ status: false })}
        />
      )}
      <div
        className={`flex px-[5px] py-[10px] ${openSetionsList ? "border-b-2" : ""}  border-gray-300 items-start relative pb-[35px]`}
      >
        <figure className="w-[100px] h-[70px] ml-[10px]">
          <div
            className="imge_bg rounded-md block bg-gray-200 "
            style={{ backgroundImage: `url(${courses.image})` }}
          ></div>
        </figure>
        <div className="flex flex-col w-full">
          <div className="w-full flex justify-between">
            <div className="flex flex-wrap items-center">
              <h4>
                كورس:{" "}
                <span className="text_secondary font-bold ml-[10px]">
                  {courses.name}
                </span>
              </h4>
              <button
                className="ml-[10px]"
                onClick={() =>
                  setopenConfirmDeleteModal({
                    status: true,
                    APIurl: `students/${prames.id}/remove-content/course`,
                    message: `حذف الكورس ${courses.name}`,
                    id: courses.id,
                  })
                }
              >
                <DeleteIcon />
              </button>
            </div>
            <h6 className=" mt-[10px]  ">
              {courses?.enrollmentRequestId
                ? "قبول الطلب في"
                : "تمت الإضافة في"}
              {convertToDate(courses?.enrolledAt)}
            </h6>
          </div>
          {courses.wholeCourse && (
            <h5 className="text-[14px] mt-[15px]">
              سيظهر له أي قسم جديد تم إضافته للطلبة المشتركين في الكورس كامل
            </h5>
          )}
        </div>
        <button
          className=" absolute left-[1px] bottom-4 p-[5px] "
          onClick={() => setopenSetionsList(!openSetionsList)}
        >
          {openSetionsList ? (
            <TopArrow stroke="black" />
          ) : (
            <ButtonArrow stroke="black" />
          )}
        </button>
      </div>
      {openSetionsList && (
        <div>
          <div className="w-[39%] mt-[20px] mb-[10px] ">
            <span className="font-bold text_secondary">الأقسام</span>{" "}
          </div>
          {courses.sections.map((elem, index) => (
            <div
              key={index}
              className="flex justify-between py-[7px] flex-wrap md:border-b-0 border-b-2 border-gray-200 "
            >
              <h5 className="md:w-[39%] w-full font-bold ">{elem.name}</h5>
              <h5 className="md:w-[29%] w-full text-gray-500">
                {elem?.enrollmentRequestId ? "قبول الطلب في" : "تمت الإضافة في"}
                {convertToDate(elem.enrolledAt)}
              </h5>
              <div className="md:w-[29%] w-full flex justify-center">
                <button
                  onClick={() =>
                    setopenConfirmDeleteModal({
                      status: true,
                      APIurl: `students/${prames.id}/remove-content/section`,
                      message: `حذف القسم ${elem.name}`,
                      id: elem.id,
                    })
                  }
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
StudentCourseCard.propTypes = {
  courses: PropTypes.object,
  index: PropTypes.number,
  updateCourses: PropTypes.func,
};
