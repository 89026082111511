import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ResultMessage from "../../reuseables/ResultMessage";

export default function StudentPermissions({ permissions }) {
  useEffect(() => {
    console.log(permissions);
  }, []);
  return (
    <div>
      <h4 className="text-center bg-white rounded-md p-[20px]">
        في الوقت الحالي جميع الطلبة على وضع الصلاحيات المحددة من صفحة الإعدادات
        قريبا سيمكنك تحديد صلاحيات كل طالب على حده
      </h4>
      {permissions.loader.status == "done" ? (
        <>
          <ul>
            {Object.keys(permissions.data).map((elem, index) => (
              <li
                key={index}
                className="w-full flex justify-between my-[20px] font-semibold"
              >
                <h5>{permissions.data[elem].arabicName}</h5>
                <button
                  disabled={true}
                  className={` p-1 w-14 my_rounded opacity-80  ${permissions.data[elem].currentValue ? "bg-gray-500" : " bg-gray-300"} `}
                  style={{ borderRadius: "13px" }}
                >
                  <div
                    className={`bg-white h-5 w-5 circle_rounded ${permissions.data[elem].currentValue ? "mr-auto" : "ml-auto"}`}
                  ></div>
                </button>
              </li>
            ))}
          </ul>
          <div className="w-full bg-gray-400 h-[1px]"></div>
          <ul>
            {permissions.data.mobileApp && (
              <>
                <h4 className="font-bold mt-[15px] ">
                  صلاحيات تطبيق الموبايل:
                </h4>
                {Object.keys(
                  permissions.data.mobileApp.mobileAppPermissions
                ).map((elem, index) => (
                  <li
                    key={index}
                    className="w-full flex justify-between my-[20px]"
                  >
                    <h5>
                      {
                        permissions.data.mobileApp.mobileAppPermissions[elem]
                          .arabicName
                      }
                    </h5>
                    <button
                      disabled={true}
                      className={` p-1 w-14 my_rounded opacity-80  ${permissions.data.mobileApp.mobileAppPermissions[elem].currentValue ? "bg-gray-500" : " bg-gray-300"} `}
                      style={{ borderRadius: "13px" }}
                    >
                      <div
                        className={`bg-white h-5 w-5 circle_rounded ${permissions.data.mobileApp.mobileAppPermissions[elem].currentValue ? "mr-auto" : "ml-auto"}`}
                      ></div>
                    </button>
                  </li>
                ))}
              </>
            )}
          </ul>
        </>
      ) : permissions.loader.status == "loading" ? (
        <div className="w-full">
          <div className="bg-white h-[50px] w-full my-[15px]"></div>
          <div className="bg-white h-[50px] w-full my-[15px]"></div>
          <div className="bg-white h-[50px] w-full my-[15px]"></div>
          <div className="bg-white h-[50px] w-full my-[15px]"></div>
          <div className="bg-white h-[50px] w-full my-[15px]"></div>
          <div className="bg-white h-[50px] w-full my-[15px]"></div>
        </div>
      ) : (
        <ResultMessage message={permissions.loader.text} />
      )}
    </div>
  );
}
StudentPermissions.propTypes = {
  permissions: PropTypes.array,
};
