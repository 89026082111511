import React, { useEffect, useState } from "react";
import StudentInfoCard from "../../components/pagesComponents/student/StudentInfoCard";
import StudentCoursesContent from "../../components/pagesComponents/student/StudentCoursesContent";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import { useParams } from "react-router-dom";
import PrevRequests from "../../components/pagesComponents/students/PrevRequests";
import CurrentRequests from "../../components/pagesComponents/students/CurrentRequests";
import StudentPermissions from "../../components/pagesComponents/student/StudentPermissions";

export default function Student() {
  const [activeContent, setactiveContent] = useState("الكورسات");
  const [courses, setcourses] = useState({
    data: [],
    hiddenContent: "",
    loader: { status: "loading", text: "" },
  });
  const [currentPermessions, setcurrentPermessions] = useState({
    loader: { status: "loading" },
    data: {},
  });
  const prames = useParams();
  const handleGetCourses = () => {
    myFeach(`students/${prames.id}/enrolled-courses`, "GET", {}, "token")
      .then(({ data }) => {
        if (data.courses.length == 0) {
          setcourses({
            data: [],
            loader: {
              status: "failed",
              text: "هذا الطالب غير مشترك في أي كورس حالياً",
            },
            hiddenContent: data?.hiddenContent,
          });
        } else {
          setcourses({
            loader: { status: "done" },
            data: data.courses,
            hiddenContent: data?.hiddenContent,
          });
        }
      })
      .catch((err) => {
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل  كورسات الطالب";
        setcourses({ loader: { status: "failed", text: errText } });
      });
  };
  const handleGetPermession = () => {
    myFeach(
      "teachers/application-settings?applicationSettings=permissions",
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        setcurrentPermessions({
          data: data.application.permissions,
          loader: { status: "done" },
        });
      })
      .catch((err) => {
        console.log(err);
        setcurrentPermessions({
          data: {},
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل الصلاحيات الحالية",
          },
        });
      });
  };
  useEffect(() => {
    handleGetCourses();
    handleGetPermession();
  }, []);
  useEffect(() => {
    console.log(currentPermessions.data);
  }, [currentPermessions]);
  return (
    <main>
      <div className="flex justify-between">
        <StudentInfoCard />
        <div className=" my_rounded p-[20px] w-full md:w-[73%]">
          <div className="flex m-auto flex-wrap justify-start mb-[10px] ">
            {filters.map((elem, index) => (
              <button
                className={`${activeContent == elem ? "bg_secondary" : "bg-white"} px-[10px] py-[5px] shadow-md rounded-md ml-[10px] mb-[15px]`}
                key={index}
                onClick={() => setactiveContent(elem)}
              >
                {elem}
              </button>
            ))}
          </div>
          {activeContent === "الكورسات" && (
            <StudentCoursesContent
              courses={courses}
              updateCourses={handleGetCourses}
            />
          )}
          {activeContent === "الطلبات" && (
            <>
              <h4 className="font-bold">الطلبات الحالية:</h4>
              <CurrentRequests studentId={prames.id} />
              <h4 className="font-semibold">الطلبات السابقة:</h4>
              <PrevRequests studentId={prames.id} />
            </>
          )}
          {activeContent === "الصلاحيات" && (
            <StudentPermissions permissions={currentPermessions} />
          )}
          {activeContent === "النشاط" && (
            <h4 className="text-center w-full bg-white rounded-md py-[25px] ">
              هذه الصفحة ستكون متوفرة قريبا
            </h4>
          )}
        </div>
      </div>
    </main>
  );
}
const filters = ["الكورسات", "الطلبات", "الصلاحيات", "النشاط"];
