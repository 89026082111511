import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RedWorning from "../reuseables/RedWorning";

export default function CompleteDataWorning({ TapBarData }) {
  const [message, setmessage] = useState("");
  useEffect(() => {
    if (
      TapBarData?.unCompletedApplicationData ||
      TapBarData?.unCompletedProfileData
    ) {
      setmessage("من فضلك قم باستكمال بياناتك عن طريق الذهاب لصفحة الإعدادات للبدء في استخدام النظام");

    }
    if (TapBarData?.blocked) {
      setmessage("نأسف لأنه تم حظرك من قبل أحد مشرفينا في حالة وجود شكوى قم بالتواصل معنا");
    }
    if (TapBarData?.subscriptionEnded) {
      setmessage("انتهاء صلاحية اشتراكك ، برجاء تجديد الاشتراك لاستمرار استخدام النظام");
    }
  }, [TapBarData]);
  return (
    <>
      {message && (
        <div className="bg_greay pt-4 warning" id="warning">
          <div className=" bg-red-100   py-10 px-5 my_rounded w-full m-auto ">
            <div className="flex items-start">

              <RedWorning />
              <h4 className="mx-2 text_danger">{message}</h4>
            </div>
            <h5 className="mt-2">{}</h5>
          </div>
        </div>
      )}
    </>
  );
}
CompleteDataWorning.propTypes = {
  TapBarData: PropTypes.any,
};

