import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import EscIcon from "../icons/EscIcon";
import image from "../../assets/updateImg.png";
export default function UpdateAlert() {
  const [showPointsAlert, setshowPointsAlert] = useState();
  const close = () => {
    setshowPointsAlert(false);
  };
  const DontShowAgain = () => {
    localStorage.setItem("dontShowUpdateAletr", true);
    close();
  };
  useEffect(() => {
    if (!localStorage.getItem("dontShowUpdateAletr") && Cookies.get("token")) {
      setTimeout(() => {
        setshowPointsAlert(true);
      }, 1000);
    }
  }, [Cookies.get("token")]);
  return (
    <>
      {showPointsAlert && (
        <div
          className="modal_massage_content  max-h-[400px] md:max-h-[85vh] w-[90%] md:w-fit "
          style={{
            transform: "translate(-50%, 0%)",
            top: "10px",
            padding: "5px 5px 5px 0px",
          }}
        >
          <div className="overflow-y-scroll hide_scoller p-[15px] max-h-[350px] md:max-h-[80vh]">
            <div className="flex justify-end  pb-[15px] ">
              <button onClick={close} className="scale-50 md:scale-100">
                <EscIcon type="rounded" stroke="#4D4D4D" />
              </button>
            </div>
            <div className="flex lg:flex-row flex-col-reverse items-center">
              <div>
                <h3 className="text_secondary font-bold mb-[20px] ">
                  تعرّف على الجديد في هذا الإصدار
                </h3>
                <h6>
                  مرحبًا بكم في الإصدار الجديد من نظام (ديسيف)! يسعدنا أن نقدّم
                  لكم ميزات جديدة تهدف لتسهيل عملك والتحكم في الطلبة المشتركين
                  لديك:
                </h6>
                <ul className="my-[20px]">
                  <li className="mb-[8px]">
                    <h4 className="text_primary font-semibold text-[16px] ">
                      1- إضافة قسم جديد مباشرة عند إنشائه للطلبة المشتركة في
                      الكورس كامل
                    </h4>
                    <h4 className="text-[14px]">
                      عند إنشاء قسم جديد ، يمكنك تفعيل خاصية إظهار القسم للطلبة
                      المشتركين في الكورس كامل من قبل دون الحاجة إلى قيام الطلبة
                      بإرسال طلبات جديدة وقبولها
                    </h4>
                  </li>
                  <li className="mb-[8px]">
                    <h4 className="text_primary font-semibold text-[16px] ">
                      2- إدارة الكورسات الخاصة بكل طالب
                    </h4>
                    <h4 className="text-[14px]">
                      أصبح بإمكانك معرفة الكورسات التي قام كل طالب بالاشتراك
                      فيها وإضافته/إزالته مباشرة إلى/من أحد الكورسات/الأقسام
                    </h4>
                  </li>
                  <li className="mb-[8px]">
                    <h4 className="text_primary font-semibold text-[16px] ">
                      3- عرض الطلبات الخاصة بكل طالب
                    </h4>
                    <h4 className="text-[14px]">
                      يمكنك عرض جميع الطلبات (الحالية/السابقة) الخاصة بالطالب في
                      صفحته
                    </h4>
                  </li>
                </ul>
                <h5 className="font-semibold">
                  استمتع بالتحديثات الجديدة واستفد بأقصى درجة من مرونة استخدام
                  النظام في عملك !
                </h5>
                <div className="flex  mt-[15px] flex-wrap justify-center ">
                  <button
                    type="button"
                    className="mx-2  px-[8px] text-center w-full gradient_border text-[14px] py-[6px]  mb-[10px] "
                    onClick={DontShowAgain}
                  >
                    حسنا
                  </button>
                </div>
              </div>
              <div className="p-[25px] relative md:block hidden ">
                <img src={image} className="block max-w-[300px]" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
UpdateAlert.propTypes = {
  close: PropTypes.func,
  message: PropTypes.string,
  points: PropTypes.any,
};
