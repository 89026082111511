import React, { useEffect, useRef, useState } from "react";
import BellIcon from "../icons/BellIcon";
import { myFeach, parceErr } from "../../globalFunctions/Api";

export default function NoteFicationDropDown() {
  const [openNoteficationList, setopenNoteficationList] = useState(false);
  const [unrededNoteficationCount, setunrededNoteficationCount] = useState(0);
  const [noteficationList, setnoteficationList] = useState({
    loader: { status: "loading" },
    data: [],
  });
  const ListRef = useRef(null);
  const btnRef = useRef(null);
  const getNumberOfUnrededNotefication = () => {
    myFeach("unread-notifications-count", "GET", {}, "token")
      .then(({ data }) => {
        setunrededNoteficationCount(data?.unreadNotificationsCount);
      })
      .catch((err) => {
        let textErr = parceErr(err).error ? parceErr(err).error : "فشل   ";
        setunrededNoteficationCount({
          loader: { status: "failed", text: textErr },
        });
      });
  };
  const getNoteficationList = () => {
    setnoteficationList({ loader: { status: "loading" } });
    myFeach("notifications", "GET", {}, "token")
      .then(({ data }) => {
        getNumberOfUnrededNotefication();
        if (data.notifications.length) {
          setnoteficationList({
            loader: { status: "done" },
            data: data?.notifications,
          });
        } else {
          setnoteficationList({
            loader: { status: "failed", text: "لا يوجد إشعارات" },
          });
        }
        // setunrededNoteficationCount(10);
      })
      .catch((err) => {
        let textErr = parceErr(err).error ? parceErr(err).error : "فشل   ";
        setnoteficationList({
          loader: { status: "failed", text: textErr },
        });
      });
  };
  const openList = () => {
    if (openNoteficationList == false) {
      setopenNoteficationList(true);
      getNoteficationList();
    } else {
      setopenNoteficationList(false);
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ListRef.current &&
        !ListRef.current.contains(event.target) &&
        !btnRef.current.contains(event.target)
      ) {
        setopenNoteficationList(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    getNumberOfUnrededNotefication();
  }, []);
  return (
    <>
      <button ref={btnRef} className="" onClick={openList}>
        <BellIcon unrededCount={unrededNoteficationCount} />
      </button>
      {openNoteficationList && (
        <>
          {/* <button className="modal_bg "></button> */}
          <ul
            ref={ListRef}
            className=" z-[200] fixed top-[80px] left-[25px] bg-white rounded-b-[20px] shadow-md w-[200px] md:w-[350px] pt-[20px] min-h-[80px] overflow-y-scroll max-h-[600px] hide_scoller"
          >
            {noteficationList.loader.status == "done" ? (
              <>
                {noteficationList.data.map((elem, index) => (
                  <>
                    <li
                      className={`p-[10px] ${elem.read ? "opacity-[0.8]" : ""} ${index + 1 != noteficationList.data.length ? "border-b-[1px]" : ""}  border-b-gray-400 `}
                      key={index}
                    >
                      <h5 className="font-bold">{elem.title} </h5>
                      <h6>{elem.body}</h6>
                    </li>
                  </>
                ))}
              </>
            ) : noteficationList.loader.status == "loading" ? (
              <div className="loader m-auto"></div>
            ) : (
              <h4 className="text-red-400 text-center">
                {noteficationList.loader.text}
              </h4>
            )}
          </ul>
        </>
      )}
    </>
  );
}
