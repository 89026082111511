import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PasswordIcon from "../icons/PasswordIcon";
import InputScaleable from "./InputScaleable";
import { cutString } from "../../globalFunctions/TextModify";
export default function CustomInput({
  width = "300px",
  height = "50px",
  lable = "",
  comment = "",
  placeHolder = "",
  required = false,
  type = "text",
  name = "",
  setformData,
  formData,
  options = [{ name: "", value: "" }],
  lang = "ar",
  fontSize = "20px",
  disabled = false,
  styleClass = "",
  containerClass = "",
  getOldDataLoader = "done",
  value = "",
  loader = { status: "done" },
  onchangeFun,
  checkBoxLabel,
}) {
  const [inputType, setinputType] = useState(type);
  const onChange = (e) => {
    if (onchangeFun) {
      onchangeFun(e);
    } else {
      setformData({
        ...formData,
        data: { ...formData?.data, [name]: e.target.value },
        errors: e.target.value
          ? { ...formData.errors, [name]: "" }
          : { ...formData.errors },
      });
    }
  };
  useEffect(() => {
    if (type === "password" && inputType === "text") {
      setTimeout(() => {
        setinputType("password");
      }, 2000);
    }
  }, [inputType]);
  return (
    <>
      {getOldDataLoader === "done" ? (
        <div className={`${containerClass} flex flex-col relative `}>
          {lable && (
            <label className="font-bold text-sm">
              {lable} {required && <span className="text_danger">*</span>}{" "}
            </label>
          )}
          {type === "select" ? (
            <>
              {loader.status === "done" ? (
                <select
                  className={`${styleClass} mt-2  ${formData?.errors[name] ? "danger_border" : ""} `}
                  style={{ width: width, height: height }}
                  onChange={onChange}
                  disabled={disabled}
                  defaultValue={
                    formData?.data[name] || formData?.data[name] == false
                      ? formData?.data[name]
                      : ""
                  }
                >
                  {options.map((elem, index) => (
                    <option
                      value={elem.value ? elem.value : elem.id}
                      key={index}
                    >
                      {elem.name}
                    </option>
                  ))}
                </select>
              ) : (
                <>
                  <div className="input mt-2 items-center  flex">
                    {loader.status === "loading" && (
                      <div className="loader"></div>
                    )}
                    {loader.status === "failed" && (
                      <h4 className="text_danger text-xs">{loader.text}</h4>
                    )}
                  </div>
                </>
              )}
            </>
          ) : type === "textArea" ? (
            <textarea
              name={name}
              value={value ? value : formData?.data[name]}
              type={inputType}
              className={` ${styleClass} mt-2 ${type == "password" ? "p-4" : ""} ${formData?.errors[name] ? "danger_border" : ""} ${lang == "en" ? "direction_left" : ""} ${isEnglish(formData?.data[name]) ? "direction_left" : ""} `}
              placeholder={placeHolder}
              style={{ width: width, height: height, fontSize: fontSize }}
              onChange={onChange}
              disabled={disabled}
            />
          ) : type === "checkBox" ? (
            <div className="flex items-start">
              <input
                type="checkbox"
                name={name}
                onChange={(e) =>
                  onChange({ target: { value: e.target.checked } })
                }
                disabled={disabled}
                className="me-[7px] w-[15px] h-[15px] mt-[5px]  "
                checked={
                  value
                    ? value
                    : formData?.data[name]
                      ? formData?.data[name]
                      : false
                }
              />
              <lable className="font-bold text-blue-600">{checkBoxLabel}</lable>
            </div>
          ) : (
            <input
              value={value ? value : formData?.data[name]}
              name={name}
              type={inputType}
              className={`${styleClass} mt-2 ${type == "password" ? "pl-9" : ""} ${formData?.errors[name] ? "danger_border" : ""} ${lang == "en" ? "direction_left" : ""} ${isEnglish(formData?.data[name]) ? "direction_left" : ""}`}
              placeholder={placeHolder}
              style={{ width: width, height: height, fontSize: fontSize }}
              onChange={onChange}
              disabled={disabled}
            />
          )}

          {!formData?.errors[name] && (
            <h6 className="text-xs text_primary mt-1">{comment}</h6>
          )}
          <h4 className="text-xs text_danger mt-1">
            {cutString(formData?.errors[name], 150)}
          </h4>
          {type === "password" && (
            <button
              disabled={formData?.data[name] ? false : true}
              type="button"
              onClick={() => {
                inputType === "password"
                  ? setinputType("text")
                  : setinputType("password");
              }}
              className="absolute left-3 top-12"
            >
              <PasswordIcon hilight={inputType === "password" ? false : true} />
            </button>
          )}
        </div>
      ) : (
        <InputScaleable
          width={width}
          height={height}
          containerClass={containerClass}
          styleClass={styleClass}
        />
      )}
    </>
  );
}
CustomInput.propTypes = {
  width: PropTypes.string,
  value: PropTypes.string,
  getOldDataLoader: PropTypes.string,
  height: PropTypes.string,
  lable: PropTypes.string,
  comment: PropTypes.string,
  lang: PropTypes.string,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  fontSize: PropTypes.string,
  containerClass: PropTypes.string,
  styleClass: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  setformData: PropTypes.any,
  formData: PropTypes.object,
  options: PropTypes.any,
  loader: PropTypes.object,
  onchangeFun: PropTypes.any,
  checkBoxLabel: PropTypes.string,
};
function isEnglish(text) {
  if (text) {
    return /^[A-Za-z\s.,?!'"()]*$/.test(text);
  } else {
    return false;
  }
}
