import React from "react";

export default function StudentCoursesSkeltn() {
  return (
    <>
      <div className="w-full bg-white my_rounded h-[350px] p-[20px] mt-[25px] ">
        <div className="flex">
          <div className="bg-gray-100 rounded-md w-[25%] h-[100px]  "></div>
          <div className="flex-col flex justify-between w-[65%] mr-[10px] ">
            <div className="bg-gray-100 w-full h-[25px] mt-[4px] "></div>
          </div>
        </div>
        <div className="bg-gray-100 w-full h-[50px]  mt-[25px]"></div>
        <div className="bg-gray-100 w-full h-[50px]  mt-[10px]"></div>
        <div className="bg-gray-100 w-full h-[50px]  mt-[10px] mb-[10px]"></div>
      </div>
      <div className="bg-white mt-[25px] p-[20px] my_rounded">
        <div className="flex w-full">
          <div className="bg-gray-100 rounded-md w-[25%] h-[100px]  "></div>
          <div className="flex-col flex justify-between w-[65%] mr-[10px] ">
            <div className="bg-gray-100 w-full h-[25px] mt-[4px] "></div>
          </div>
        </div>
      </div>
      <div className="bg-white mt-[25px] p-[10px] my_rounded">
        <div className="flex w-full">
          <div className="bg-gray-100 rounded-md w-[25%] h-[100px]  "></div>
          <div className="flex-col flex justify-between w-[65%] mr-[10px] ">
            <div className="bg-gray-100 w-full h-[25px] mt-[4px] "></div>
          </div>
        </div>
      </div>
    </>
  );
}
