import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AddDocIcon from "../icons/AddDocIcon";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import RightIcon from "../icons/RightIcon";
import InputScaleable from "./InputScaleable";
import LoaderModal from "./LoaderModal";
import DeleteIcon from "../icons/DeleteIcon";
import { cutString } from "../../globalFunctions/TextModify";
import DeleteFileConfirm from "../pagesComponents/courses/DeleteFileConfirm";
import SucsessMessage from "./SucsessMessage";
export default function CustomUplodeDoc({
  lable,
  required,
  disabled,
  formData,
  setformData,
  name = "",
  apiUrl,
  getOldDataLoader = "done",
}) {
  const [openConfirmDeleteModal, setopenConfirmDeleteModal] = useState(false);
  const [deleteDocLoader, setdeleteDocLoader] = useState({
    status: "",
    text: "",
  });
  const [openDeleteFeedBack, setopenDeleteFeedBack] = useState({
    status: false,
    isSucsess: true,
  });
  const inputRef = useRef(null);
  const [loader, setloader] = useState("noAction");
  const [uplodeErr, setuplodeErr] = useState();

  const uplodeDoc = async (e) => {
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    Imageformdata.append("file", file);
    if (validatePdf(file)) {
      setloader("loading");
      setuplodeErr("");
      myFeach(
        `upload-file/?type=${apiUrl}`,
        "POST",
        Imageformdata,
        "token",
        true
      )
        .then(({ data }) => {
          inputRef.current.value = "";
          setloader("done");
          setuplodeErr("");
          setformData({
            ...formData,
            data: {
              ...formData.data,
              [name]: { protected: true, url: data.file },
            },
            errors: { ...formData.errors, [name]: "" },
          });
        })
        .catch((err) => {
          console.log(err);
          inputRef.current.value = "";
          setloader("failed");
          setuplodeErr(
            parceErr(err)?.error
              ? parceErr(err).error
              : "فشل رفع الصورة حاول مرة أخرى"
          );
        });
    } else {
      setuplodeErr("مسموح برفع الملف بصيغة pdf فقط");
    }
  };
  const handleDeleteDoc = () => {
    const body = { file: formData.data[name]?.url };
    if (window.location.pathname.includes("edit")) {
      setdeleteDocLoader({ status: "done" });
      setformData({
        ...formData,
        data: { ...formData.data, [name]: {} },
      });
      setopenDeleteFeedBack({
        status: true,
        isSucsess: true,
        message: "تم مسح الملف بنجاح",
      });
      setopenConfirmDeleteModal(false);
    } else {
      setdeleteDocLoader({ status: "loading" });
      myFeach("delete-file", "PUT", body, "token")
        .then(() => {
          setdeleteDocLoader({ status: "done" });
          setloader({ status: "" });
          // const updatedFormData = { ...formData.data };
          // delete updatedFormData[name];
          setformData({
            ...formData,
            data: { ...formData.data, [name]: { protected: true, file: "" } },
          });
          setopenDeleteFeedBack({
            status: true,
            isSucsess: true,
            message: "تم مسح الملف بنجاح",
          });
          setopenConfirmDeleteModal(false);
        })
        .catch((err) => {
          let textErr = parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل حذف الملف";
          setdeleteDocLoader({
            status: "failed",
            text: cutString(textErr, 25),
          });
          setopenDeleteFeedBack({
            status: true,
            isSucsess: false,
            message: cutString(textErr, 25),
          });
        });
    }
  };
  useEffect(() => {
    if (!formData.data[name]) {
      setloader("noAction");
    }
  }, [formData.data[name]]);
  return (
    <>
      {loader === "loading" && <LoaderModal lable="جاري رفع الملف" />}
      {openDeleteFeedBack.status && (
        <SucsessMessage
          close={() => setopenDeleteFeedBack({ status: false })}
          isSucsess={openDeleteFeedBack.isSucsess}
          message={openDeleteFeedBack.message}
        />
      )}
      {openConfirmDeleteModal && (
        <DeleteFileConfirm
          fileType="الملف"
          loader={deleteDocLoader}
          confirm={handleDeleteDoc}
          close={() => setopenConfirmDeleteModal(false)}
        />
      )}
      {getOldDataLoader === "done" ? (
        <div className={`mb-10 `}>
          <label className="mb-5 block font-bold text-sm ">
            {lable} {required && <span className="text_danger">*</span>}{" "}
          </label>
          <div className="flex items-center  ">
            {formData.data[name]?.url ? (
              <div className=" w-44 h-28 my_rounded flex flex-col items-center justify-center ml-4 bg-white ">
                <div className="bg-green-400 circle_rounded p-1">
                  <RightIcon />
                </div>
                <a
                  target="_blank"
                  href={formData.data[name]?.url}
                  rel="noreferrer"
                  className="hover:text-blue-400  "
                >
                  عرض الملف
                </a>
              </div>
            ) : (
              <button
                disabled={disabled}
                type="button"
                onClick={() => inputRef.current.click()}
                className={` relative w-44 h-28 my_rounded flex items-center justify-center ml-4 bg-white   ${formData?.errors[name] || uplodeErr ? "danger_border" : ""}`}
              >
                <div>
                  {loader == "loading" && <div className="loader"></div>}
                  {loader != "loading" && !formData.data[name]?.url && (
                    <AddDocIcon />
                  )}
                </div>
              </button>
            )}

            <h4>قم برفع المستند بصيغة (pdf)</h4>

            <input
              ref={inputRef}
              disabled={disabled}
              type="file"
              className="display_none"
              onChange={uplodeDoc}
            />
          </div>
          {formData.data[name]?.url && (
            <button
              disabled={deleteDocLoader === "loading" ? true : false}
              type="button"
              className="mt-4"
              onClick={() => setopenConfirmDeleteModal(true)}
            >
              <div className="flex hover:text-red-500 items-center ">
                <h6 className="ml-2 text-xs ">لرفع ملف جديد قم بمسح القديم</h6>
                <DeleteIcon />
              </div>
            </button>
          )}
          <h4 className="text-xs text_danger mt-2">{formData?.errors[name]}</h4>
          <h4 className="text-xs text_danger mt-2">{uplodeErr}</h4>
          {formData.data[name]?.url && (
            <div className="flex items-center">
              <input
                type="checkbox"
                className="ml-[10px]"
                // disabled={formData.data[name]?.url ? false : true}
                checked={!formData.data[name]?.protected}
                onChange={(e) =>
                  setformData({
                    ...formData,
                    data: {
                      ...formData.data,
                      [name]: {
                        ...formData.data[name],
                        protected: !e.target.checked,
                      },
                    },
                  })
                }
              />
              <lable> السماح للطالب بتحميل الملف</lable>
            </div>
          )}
        </div>
      ) : (
        <InputScaleable width="70%" height="40px" />
      )}
    </>
  );
}
CustomUplodeDoc.propTypes = {
  lable: PropTypes.string,
  apiUrl: PropTypes.string,
  btn_class: PropTypes.string,
  name: PropTypes.string,
  getOldDataLoader: PropTypes.string,
  formData: PropTypes.object,
  setformData: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
let validatePdf = (file) => {
  let flag = "";
  const allowedTypes = ["application/pdf"];
  if (allowedTypes.includes(file.type)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
