import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//  auth
import Rejesteration from "./pages/auth/Rejesteration";
import OTP from "./pages/auth/OTP";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import CompleteInformation from "./pages/auth/CompleteInformation";
//  course
import Courses from "./pages/courses/Courses";
import CourseDetailes from "./pages/courses/CourseDetailes";
import OverView from "./pages/OverView";
import Statstics from "./pages/Statstics";
import Requests from "./pages/students/Requests";
import Questions from "./pages/Questions";
import Settings from "./pages/Settings";
import Students from "./pages/students/Students";
import Subscreptions from "./pages/Subscreptions";
import AddCourse from "./pages/courses/AddCourse";
import AddLeson from "./pages/courses/AddLeson";
import ProtectedRoute from "./globalFunctions/ProtectedRoute";
import Student from "./pages/students/Student";
import SideBar from "./components/common/SideBar";
import PromoCode from "./pages/PromoCode";
import { myFeach, parceErr } from "./globalFunctions/Api";
import UpdateAlert from "./components/common/UpdateAlert";
function App() {
  const [TapBarData, setTapBarData] = useState({
    loader: { status: "loading", text: "" },
    data: "",
  });
  const getTeacherGenralData = () => {
    myFeach("top-bar", "GET", {}, "token")
      .then(({ data }) => {
        setTapBarData({ loader: { status: "done", text: "" }, data: data });
      })
      .catch((err) => {
        let textErr = parceErr(err).error
          ? parceErr(err).error
          : "فشل تحميل اسم المدرس";
        setTapBarData({ loader: { status: "failed", text: textErr } });
      });
  };
  return (
    <>
      <BrowserRouter>
        <UpdateAlert />
        <SideBar
          getTeacherGenralData={getTeacherGenralData}
          TapBarData={TapBarData}
        />
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <OverView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/statstics"
            element={
              <ProtectedRoute>
                <Statstics />
              </ProtectedRoute>
            }
          />
          {/* courses */}
          <Route
            path="/courses"
            element={
              <ProtectedRoute>
                <Courses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/course-detailes/:id/:name"
            element={
              <ProtectedRoute>
                <CourseDetailes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-course"
            element={
              <ProtectedRoute>
                <AddCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-course/:id/:name"
            element={
              <ProtectedRoute>
                <AddCourse />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-lesson/:course_id/:course_name/:section_id/:section_name"
            element={
              <ProtectedRoute>
                <AddLeson />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-lesson/:course_id/:course_name/:section_id/:section_name/:leson_id"
            element={
              <ProtectedRoute>
                <AddLeson />
              </ProtectedRoute>
            }
          />
          {/* end courses */}
          <Route
            path="/requests"
            element={
              <ProtectedRoute>
                <Requests />
              </ProtectedRoute>
            }
          />
          <Route
            path="/students"
            element={
              <ProtectedRoute>
                <Students />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student/:id"
            element={
              <ProtectedRoute>
                <Student />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscreptions"
            element={
              <ProtectedRoute>
                <Subscreptions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/questions"
            element={
              <ProtectedRoute>
                <Questions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/setting"
            element={
              <ProtectedRoute>
                <Settings regetTopBarData={getTeacherGenralData} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/promo-code"
            element={
              <ProtectedRoute>
                <PromoCode />
              </ProtectedRoute>
            }
          />

          <Route path="/signup" element={<Rejesteration />} />
          <Route
            path="/log-in"
            element={<Login regetTopBarData={getTeacherGenralData} />}
          />
          <Route
            path="/log-in/auth-problem"
            element={<Login regetTopBarData={getTeacherGenralData} />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/complete-information"
            element={
              <ProtectedRoute>
                <CompleteInformation />
              </ProtectedRoute>
            }
          />
          <Route path="/:type/otp/:phone" element={<OTP />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
