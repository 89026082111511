import React, { useState } from "react";
import PropTypes from "prop-types";
import ResultMessage from "../../reuseables/ResultMessage";
import StudentCoursesSkeltn from "./StudentCoursesSkeltn";
import StudentCourseCard from "./StudentCourseCard";
import AddContentToStudent from "./AddContentToStudentModal";

export default function StudentCoursesContent({ courses, updateCourses }) {
  const [openAddContentToStudentModal, setopenAddContentToStudentModal] =
    useState(false);
  return (
    <div>
      {openAddContentToStudentModal && (
        <AddContentToStudent
          updateContent={updateCourses}
          close={() => setopenAddContentToStudentModal(false)}
        />
      )}

      {courses.loader.status == "done" ? (
        <div>
          <div className="flex flex-wrap items-center justify-between mb-[25px]">
            {courses?.hiddenContent && (
              <h5 className=" ml-[15px]">
                هذا الطالب مشترك في محتوى قمت بإخفائه
              </h5>
            )}
            <button
              className="w-fit  gradient_border  px-4 py-2 mr-auto "
              onClick={() => setopenAddContentToStudentModal(true)}
            >
              إضافة محتوى آخر للطالب
            </button>
          </div>
          {courses.data.map((elem, index) => (
            <StudentCourseCard
              key={index}
              courses={elem}
              index={index}
              updateCourses={updateCourses}
            />
          ))}
        </div>
      ) : courses.loader.status == "loading" ? (
        <StudentCoursesSkeltn />
      ) : (
        <ResultMessage message={courses.loader.text} />
      )}
    </div>
  );
}

StudentCoursesContent.propTypes = {
  courses: PropTypes.object,
  updateCourses: PropTypes.func,
};
