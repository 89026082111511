import React from "react";
import PropTypes from "prop-types";

export default function ProtectedIcon({iconClass}) {
  return (
    <svg
      className={iconClass}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 1.16675C7.90555 1.16671 8.77588 1.51766 9.42811 2.14585C10.0803 2.77404 10.4637 3.63058 10.4977 4.5355L10.5 4.66675H11.0833C11.3777 4.66666 11.6612 4.77782 11.877 4.97795C12.0928 5.17809 12.225 5.45241 12.2471 5.74592L12.25 5.83342V11.6667C12.2501 11.9611 12.1389 12.2446 11.9388 12.4604C11.7387 12.6762 11.4643 12.8084 11.1708 12.8305L11.0833 12.8334H2.91667C2.62233 12.8335 2.33884 12.7223 2.12301 12.5222C1.90719 12.3221 1.77499 12.0478 1.75292 11.7542L1.75 11.6667V5.83342C1.74991 5.53908 1.86107 5.25558 2.06121 5.03976C2.26134 4.82394 2.53566 4.69174 2.82917 4.66967L2.91667 4.66675H3.5C3.5 3.73849 3.86875 2.84825 4.52513 2.19187C5.1815 1.5355 6.07174 1.16675 7 1.16675ZM11.0833 5.83342H2.91667V11.6667H11.0833V5.83342ZM7 7.00008C7.24884 7.00016 7.49114 7.0798 7.69151 7.22738C7.89187 7.37495 8.0398 7.58272 8.11368 7.82034C8.18757 8.05797 8.18354 8.31299 8.10219 8.54816C8.02084 8.78333 7.86642 8.98633 7.6615 9.1275L7.58333 9.17708V9.91675C7.58317 10.0654 7.52624 10.2084 7.42417 10.3165C7.32211 10.4247 7.18261 10.4897 7.03419 10.4984C6.88576 10.5071 6.73961 10.4589 6.6256 10.3634C6.51158 10.268 6.43831 10.1326 6.42075 9.985L6.41667 9.91675V9.17708C6.19427 9.04866 6.02045 8.85045 5.92218 8.61318C5.82391 8.37592 5.80667 8.11285 5.87314 7.86479C5.93961 7.61673 6.08607 7.39753 6.28981 7.24118C6.49355 7.08484 6.74319 7.00009 7 7.00008ZM7 2.33341C6.38116 2.33341 5.78767 2.57925 5.35008 3.01683C4.9125 3.45442 4.66667 4.04791 4.66667 4.66675H9.33333C9.33333 4.04791 9.0875 3.45442 8.64992 3.01683C8.21233 2.57925 7.61884 2.33341 7 2.33341Z"
        fill="url(#paint0_linear_10132_2447)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10132_2447"
          x1="1.74999"
          y1="12.8334"
          x2="13.3522"
          y2="2.39141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
ProtectedIcon.propTypes = {
  iconClass: PropTypes.string,
};