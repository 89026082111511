import React from "react";
import PropTypes from "prop-types";

export default function TopArrow({ stroke = "white" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
    >
      <path
        d="M14.9961 7L8.77704 1.66938C8.32766 1.2842 7.66453 1.2842 7.21514 1.66938L0.996095 7"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
TopArrow.propTypes = {
  stroke: PropTypes.string,
};
