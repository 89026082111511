import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MonyIcon from "../icons/MonyIcon";
import ListIcon from "../icons/ListIcon";
import { Link, useLocation } from "react-router-dom";
import NoteFicationDropDown from "./NoteFicationDropDown";
export default function TopNav({ isMobileWidth, TapBarData }) {
  const location = useLocation();
  const collapse = () => {
    let maiTagClass = document.getElementsByTagName("main")[0]?.classList;
    let navElementClass = document.getElementById("nav_list")?.classList;
    let warningMsg = document.getElementById("warning")?.classList;
    let topNav = document.getElementById("top_nav")?.classList;
    if (navElementClass?.value.includes("collapse_Side")) {
      navElementClass?.remove("collapse_Side");
      topNav?.remove("wide_top_nav");
      maiTagClass?.remove("wide_main");
      warningMsg?.remove("wide_warning");
    } else {
      maiTagClass?.add("wide_main");
      warningMsg?.add("wide_warning");
      topNav?.add("wide_top_nav");
      navElementClass?.add("collapse_Side");
    }
  };
  //   handle render main with wide style if rendering new page and navbar is already collapsed
  useEffect(() => {
    let navElementClass = document.getElementById("nav_list")?.classList;
    if (navElementClass?.value.includes("collapse_Side")) {
      let maiTagClass = document.getElementsByTagName("main")[0]?.classList;
      let warningMsg = document.getElementById("warning")?.classList;
      maiTagClass?.add("wide_main");
      warningMsg?.add("wide_warning");
    }
  }, [location]);
  return (
    <>
      <div
        className={`${isMobileWidth ? "wide_top_nav justify-center" : "justify-between"} shadow-sm top_nav w-full `}
        id="top_nav"
      >
        <div className={` px-4 flex items-center w-full`}>
          <button
            onClick={collapse}
            className={`${isMobileWidth ? "display_none" : ""}`}
          >
            <ListIcon color="#4D4D4D" />
          </button>
          {TapBarData.loader.status === "done" && (
            <div className="w-full flex justify-between items-center">
              {TapBarData.loader.status == "done" && (
                <>
                  <h4 className="m-4 top_nav_text teacher_name ">
                    <span>مرحبا</span>{" "}
                    <span className="text_secondary">
                      {TapBarData.data?.name}
                    </span>
                  </h4>
                  <div className="flex items-center">
                    {TapBarData.data.subscriptionEnded ? (
                      <h4 className="m-4 top_nav_text text_danger">
                        تم انتهاء صلاحية اشتراكك
                      </h4>

                    ) : (
                      <h4 className="m-4 top_nav_text ">
                        <span>أنت الآن على نظام </span>{" "}
                        <span className="text_secondary">
                          الباقة {TapBarData.data?.currentPlan}
                        </span>
                      </h4>
                    )}
                    <Link
                      className="p-2 my_rounded bg_greay promo_code_btn"
                      to="/promo-code"
                    >
                      <MonyIcon />
                    </Link>
                    <NoteFicationDropDown />
                    <a
                      href="https://wa.me/+201278279346"
                      target="_blank"
                      rel="noreferrer"
                      className="my_btn  mx-4 block h-fit contact_us_btn"
                      style={{ minWidth: "124px" }}
                    >
                      تواصل معنا
                    </a>
                  </div>
                </>
              )}
            </div>
          )}
          {TapBarData.loader.status == "loading" && (
            <div className="w-full flex justify-center">
              <div className="dot_loader"></div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-20"></div>
    </>
  );
}
TopNav.propTypes = {
  isMobileWidth: PropTypes.bool,
  TapBarData: PropTypes.object,
};