import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
// components
import ResultMessage from "../../reuseables/ResultMessage";
import DetailedDeletePrompet from "./DetailedDeletePrompet";
import CoursePrompetCard from "./CoursePrompetCard";
import AddSectionModal from "./AddSectionModal";
// icons
import VisibilityIcon from "../../icons/VisibilityIcon";
import EditIcon from "../../icons/EditIcon";
import GroupIcon from "../../icons/GroupIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import VideoIcon from "../../icons/VideoIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import CourseSection from "./CourseSection";
import CoursesIcon from "../../icons/CoursesIcon";

export default function CourseDetailesCard({ handleRegetList }) {
  const prams = useParams();
  const [courseDetailes, setcourseDetailes] = useState({
    detailes: {},
    loader: { status: "loading" },
  });
  const [openAddSectionModal, setopenAddSectionModal] = useState({
    id: "",
    status: "",
  });

  const [openDeleteConfirm, setopenDeleteConfirm] = useState({});
  const handleGetCourseDetailes = () => {
    // if (courseDetailes.loader.status !== "loading") {
    //   setcourseDetailes({
    //     ...courseDetailes,
    //     loader: { status: "loading", text: "" },
    //   });
    // }
    myFeach(`courses/${prams.id}?content=true`, "GET", {}, "token")
      .then(({ data }) => {
        setcourseDetailes({
          detailes: data.course,
          loader: { status: "done" },
        });
      })
      .catch((err) => {
        setcourseDetailes({
          ...courseDetailes,
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل تفاصيل الكورس",
          },
        });
      });
  };
  const handleDeleteOrToggleCourse = (type) => {
    const deletemessage = {
      prompet: { text: "هل أنت متأكد من حذف هذا الكورس", color: "red" },
      sucsess: "تم حذف الكورس بنجاح",
      failed: "فشل حذف الكورس حاول مرة أخرى",
    };
    const ShowTogglemessage = {
      prompet: {
        text: courseDetailes.detailes.hidden
          ? "هل أنت متأكد من إظهار هذا الكورس"
          : "هل أنت متأكد من إخفاء الكورس",
      },
      sucsess: courseDetailes.detailes.hidden
        ? "تم إظهار الكورس بنجاح"
        : "تم إخفاء الكورس بنجاح",
      failed: "فشل تغير حالة إظهار الكورس حاول مرة أخرى",
    };
    const afterDeleteFubction = () => {
      handleRegetList();
      setcourseDetailes({
        ...courseDetailes,
        loader: {
          status: "failed",
          text: `كورس ${courseDetailes.detailes.name} لم يعد موجود`,
        },
      });
    };
    const afterPachFunction = () => {
      handleRegetList();
      handleGetCourseDetailes();
    };
    setopenDeleteConfirm({
      status: true,
      apiUrl: `courses/${courseDetailes.detailes.id}`,
      apiMethod: type,
      afterSucsessFunction:
        type == "DELETE" ? afterDeleteFubction : afterPachFunction,
      message:
        type == "DELETE" ? { ...deletemessage } : { ...ShowTogglemessage },
    });
  };
  useEffect(() => {
    handleGetCourseDetailes();
  }, [prams]);
  return (
    <>
      {openDeleteConfirm.status && (
        <DetailedDeletePrompet
          close={() => setopenDeleteConfirm({ status: false })}
          message={openDeleteConfirm.message}
          afterDeleteFunction={openDeleteConfirm.afterSucsessFunction}
          apiUrl={openDeleteConfirm.apiUrl}
          apiMethod={openDeleteConfirm.apiMethod}
        >
          <CoursePrompetCard cardDetailes={courseDetailes.detailes} />
        </DetailedDeletePrompet>
      )}
      {openAddSectionModal.status ? (
        <AddSectionModal
          regetCourseDetailes={handleGetCourseDetailes}
          close={() => setopenAddSectionModal({ status: false })}
          sectionData={openAddSectionModal.sectionData}
        />
      ) : (
        ""
      )}

      {courseDetailes.loader.status === "done" && (
        <>
          <div className="w-full my_rounded flex flex-wrap md:flex-nowrap ">
            <figure className="h-32 w-60 ml-8 mb-4 md:mb-0">
              <div
                className="imge_bg my_rounded"
                style={{
                  backgroundImage: `url(${courseDetailes.detailes?.image})`,
                }}
              ></div>
            </figure>
            <div className="flex justify-between w-full flex-wrap">
              <div className="">
                <div className="flex flex-wrap ">
                  <h3 className="font-bold">{courseDetailes.detailes.name}</h3>
                  <Link
                    to={`/edit-course/${courseDetailes?.detailes?.id}/${encodeURIComponent(courseDetailes?.detailes?.name)}`}
                    className="mx-4 block"
                  >
                    <EditIcon />
                  </Link>
                  <button
                    className="mx-2 flex items-center"
                    onClick={() => handleDeleteOrToggleCourse("PATCH")}
                  >
                    <VisibilityIcon hiddin={courseDetailes?.detailes?.hidden} />
                  </button>
                </div>
                <div className="flex md:mt-14 flex-wrap mt-2 ">
                  <div className="flex items-center ml-10 ">
                    <GroupIcon />
                    <h3 className="mx-2">
                      {" "}
                      {courseDetailes.detailes.noOfStudents}طالب
                    </h3>
                  </div>
                  <div className="flex items-center ml-10 ">
                    <VideoIcon />
                    <h3 className="mx-2">
                      {courseDetailes.detailes.noOfVideos}فيديو{" "}
                    </h3>
                  </div>
                  <div className="flex items-center ml-10 ">
                    <DocumentIcon />
                    <h3 className="mx-2">
                      {courseDetailes.detailes.noOfPdfs}مستند{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div>
                {!courseDetailes.detailes.discountedPrice ? (
                  <h3 className="font-bold text_sucsess">
                    {courseDetailes.detailes.price} ريال
                  </h3>
                ) : (
                  <div className="flex">
                    <h3 className="font-bold text_danger line-through mx-3 ">
                      {courseDetailes.detailes.price} ريال
                    </h3>
                    <h3 className="font-bold text_sucsess">
                      {courseDetailes.detailes.discountedPrice} ريال
                    </h3>
                  </div>
                )}
                <button
                  className="flex md:mt-14 mt-2 items-center"
                  onClick={() => handleDeleteOrToggleCourse("DELETE")}
                >
                  <DeleteIcon />
                  <h3 className="mx-2 text_danger">حذف الكورس</h3>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {courseDetailes.loader.status === "failed" && (
        <ResultMessage message={courseDetailes?.loader?.text} />
      )}
      {!courseDetailes.detailes.sections?.length &&
      courseDetailes.loader.status === "done" ? (
        <div className="w-full flex flex-col items-center justify-center my-20 ">
          <CoursesIcon decoration="line_throw" />
          <h3 className="mt-10 font-bold">لا يوجد أقسام بعد</h3>
        </div>
      ) : (
        <>
          {courseDetailes.loader.status === "done" && (
            <>
              {courseDetailes.detailes?.sections?.map((section, index) => (
                <CourseSection
                  index={index}
                  key={index}
                  sectionData={section}
                  regetCourseDetailes={handleGetCourseDetailes}
                />
              ))}
            </>
          )}
        </>
      )}
      {courseDetailes.loader.status === "loading" && (
        <>
          <div className="w-full flex">
            <div className=" scaleAble h-32 w-60 my_rounded"></div>
            <div className="w-full">
              <div className="w-auto h-10 mr-8 scaleAble "></div>
              <div className="w-auto h-10 mt-10 mr-8  ml-8 scaleAble"></div>
            </div>
          </div>
          <div className="w-full h-60 scaleAble mt-10 my_rounded"></div>
        </>
      )}
      {courseDetailes.loader.status !== "failed" && (
        <button
          className="w-full gradient_border mt-3 px-4 py-2"
          onClick={() =>
            setopenAddSectionModal({ sectionData: false, status: true })
          }
        >
          إضافة قسم جديد
        </button>
      )}
    </>
  );
}
CourseDetailesCard.propTypes = {
  handleRegetList: PropTypes.func,
};
