import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomInput from "../../components/reuseables/CustomInput";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import { Link, useNavigate } from "react-router-dom";
import EnterPhoneModal from "../../components/pagesComponents/login/enterPhoneModal";
import { checkRequired } from "../../globalFunctions/validation";
import PhoneInput from "../../components/reuseables/PhoneInput";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
import Cookies from "js-cookie";
import DesafeLogo from "../../components/icons/DesafeLogo";
import DesafeTextLogo from "../../components/icons/DesafeTextLogo";
export default function Login({ regetTopBarData }) {
  const navigate = useNavigate();
  const [openResetPasswordModal, setopenResetPasswordModal] = useState(false);
  const [formData, setformData] = useState({
    data: { userType: "teacher" },
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["phone", "password"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        error: "",
        errors: {},
      });
      myFeach("login", "POST", formData.data)
        .then(({ data }) => {
          setformData({
            ...formData,
            submitLoader: { status: "done" },
            errors: "",
            error: "",
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم تسجيل الدخول",
          });
          Cookies.set("token", data?.loginToken);
          regetTopBarData();
          setTimeout(() => {
            navigate("/");
          }, 1500);
        })
        .catch((err) => {
          setformData({
            ...formData,
            submitLoader: { status: "failed" },
            errors: parceErr(err)?.errors,
            error: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تسجيل الدخول",
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تسجيل الدخول",
          });
        });
    }
  };
  useEffect(() => {
    window.location.pathname.includes("auth-problem") &&
      setopenFeedBackMessage({
        status: true,
        message: "يجب تسجيل الدخول أولاً",
      });
  }, []);
  return (
    <main
      className="full_page login_page bg_primary  "
      style={{ paddingTop: "25px" }}
    >
      {openResetPasswordModal && (
        <EnterPhoneModal close={() => setopenResetPasswordModal(false)} />
      )}
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <figure className="w-full m-auto flex justify-center items-center h-20 logo   ">
        <DesafeLogo />
        <div className="text_logo">
          <DesafeTextLogo />
        </div>
      </figure>
      <form
        className="m-auto  bg_greay large_rounded w-10/12 md:w-4/12 p-10  "
        onSubmit={handleSubmit}
      >
        <h3 className="text-center font-bold text-2xl mb-12">مرحبا بعودتك</h3>
        <PhoneInput
          width="100%"
          lable={"رقم الهاتف"}
          err={false}
          required={true}
          comment="اختر الدولة أولاً"
          type=""
          name="phone"
          setformData={setformData}
          formData={formData}
          lang="en"
        />
        <CustomInput
          width="100%"
          lable={"كلمة السر"}
          placeHolder={""}
          err={false}
          required={true}
          type="password"
          name="password"
          setformData={setformData}
          formData={formData}
          containerClass="mt-6"
        />
        <button
          type="button"
          onClick={() => setopenResetPasswordModal(true)}
          className="text_primary -mt-5 h6 font-normal mb-10"
        >
          هل نسيت كلمة السر ؟
        </button>
        <CustomSubmitButton
          loader={formData.submitLoader}
          err={formData?.error}
          width="100%"
          height="47px"
          title="تسجيل الدخول"
        />
        <div className="flex justify-center w-full items-center  mt-8">
          <span>ليس لديك حساب</span>{" "}
          <Link to="/signup" className="text_primary mx-2 block">
            اشترك الآن
          </Link>
        </div>
      </form>
    </main>
  );
}
Login.propTypes = {
  regetTopBarData: PropTypes.func,
};
