import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import Pagenation from "../../reuseables/Pagenation";
import ResultMessage from "../../reuseables/ResultMessage";
import CurrentRequestCard from "./CurrentRequestCard";

export default function CurrentRequests({ studentId }) {
  const [requests, setrequests] = useState({
    data: {},
    loader: { status: "loading" },
    numberOfPages: "",
  });
  const [page, setpage] = useState(1);

  const handleGetRequests = () => {
    setrequests({ ...requests, loader: { status: "loading" } });
    let limit = 10;
    let StudentsReqapiUrl = `enrollment-requests?page=${page}&type=unresolved&limit=${limit}`;
    let oneStudentsReqapiUrl = `enrollment-requests?page=${page}&type=unresolved&limit=${limit}&student=${studentId}`;
    let apiUrl = studentId ? oneStudentsReqapiUrl : StudentsReqapiUrl;
    myFeach(apiUrl, "GET", {}, "token")
      .then(({ data }) => {
        if (data.enrollmentRequests.length) {
          setrequests({
            loader: { status: "done" },
            data: data.enrollmentRequests,
            numberOfPages: roundNumber(data.totalResults, limit),
            totalResult: data.totalResults,
          });
        } else {
          setrequests({
            loader: { status: "failed", text: "لا يوجد طلبات حالية" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل الطلبات الحالية";
        setrequests({
          ...requests,
          loader: { status: "failed", text: errText },
        });
      });
  };
  useEffect(() => {
    handleGetRequests();
  }, [page]);
  return (
    <div>
      {requests.loader.status == "done" && (
        <>
          <div className="flex flex-wrap mt-10">
            {requests.data.map((elem, index) => (
              <CurrentRequestCard
                key={index}
                request={elem}
                regetRequestis={handleGetRequests}
              />
            ))}
          </div>
          <Pagenation
            page={page}
            setPage={setpage}
            lastPage={requests.numberOfPages}
            disabled={requests.loader.status == "loading" ? true : false}
            totalResult={requests.totalResult}
            pageActualResult={requests.data?.length}
            name="طلب"
          />
        </>
      )}
      {requests.loader.status === "loading" && (
        <div className="flex flex-wrap mt-10">
          {loadersCardNumber.map((elem, index) => (
            <div
              key={index}
              className="my_rounded overflow-hidden w-56 md:w-96 h-40 md:h-80 shadow-sm m-4 bg-white p-2"
            >
              <div className="scaleAble flex justify-between p-3 h-10 my-4 ">
                {elem}
              </div>
              <div className="scaleAble flex justify-between p-3 h-16 md:h-36 my-4 "></div>
              <div className="scaleAble flex justify-between p-3 h-10 my-4 "></div>
            </div>
          ))}
        </div>
      )}
      {requests.loader.status === "failed" && (
        <ResultMessage message={requests.loader.text} />
      )}
    </div>
  );
}
CurrentRequests.propTypes = {
  studentId: PropTypes.object,
};
function roundNumber(number, limit) {
  const result = number / limit;
  if (result < 1) {
    return 1;
  } else if (result % 1 !== 0) {
    return Math.ceil(result);
  } else {
    return result;
  }
}
const loadersCardNumber = ["", "", "", ""];
